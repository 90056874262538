//获取软件列表
import {sortTree} from "@/common/js/treeHelper";

export const getSoftList = async (that) => {
    const ret = await that.http("/galaxyAuthority/soft/get");
    console.log("获取权限列表", ret);
    if (ret.success) {
        return ret.data
    } else {
        return null
    }
}
//获取用户类型
export const getUserTypeList = async (that) => {
    const ret = await that.http("/galaxyAuthority/userType/get");
    console.log("获取用户类型", ret);
    if (ret.success) {
        return ret.data
    } else {
        return null
    }
}
//获取软件下的所有权限
export const getSoftAuthority = async (that, softId) => {
    const ret = await that.http("/galaxyAuthority/softAuthority/listAll/" + softId);
    console.log("获取软件下的所有权限", ret);
    if (ret.success) {
        return (sortTree({nodeList: ret.data.nodeList}, 'refId', 'nodeList')).nodeList;
    } else {
        return null
    }
}
//新增软件下的权限
export const postSoftAuthority = async (that, form) => {
    const ret = await that.http("/galaxyAuthority/softAuthority/post", form, "POST");
    console.log("新增软件下的权限", ret);
    if (ret.success) {
        return ret.data
    } else {
        return null
    }
}
//修改软件下的权限
export const putSoftAuthority = async (that, form) => {
    const ret = await that.http("/galaxyAuthority/softAuthority/put", form, "PUT");
    console.log("修改软件下的权限", ret);
    if (ret.success) {
        return ret.data
    } else {
        return null
    }
}
//删除软件下的权限
export const deleteSoftAuthority = async (that, id) => {
    const ret = await that.http("/galaxyAuthority/softAuthority/delete/" + id, null, "DELETE");
    console.log("删除软件下的权限", ret);
    if (ret.success) {
        return ret.data
    } else {
        return null
    }
}
//获取软件下用户类型的所有权限
export const getSoftUserTypeAuthority = async (that, softId, userTypeId, resultType = 0) => {
    const ret = await that.http("/galaxyAuthority/softUsertypeAuthority/" +
        (resultType === 0 ? "listAll" : "listAllTree") + "/" + softId + "/" + userTypeId);
    console.log("获取软件下用户类型的所有权限", ret);
    if (ret.success) {
        console.log(resultType === 0);
        if (resultType === 0)
            return ret.data;
        else {
            return ret.data ? (sortTree({nodeList: ret.data.nodeList}, 'refId', 'nodeList')).nodeList : null
        }
    } else {
        return null
    }
}
//修改软件下用户类型的权限
export const putSoftUserTypeAuthority = async (that, form) => {
    const ret = await that.http("/galaxyAuthority/softUsertypeAuthority/put", form, "PUT");
    console.log("修改软件下用户类型的权限", ret);
    if (ret.success) {
        return true
    } else {
        return false
    }
}
//获取软件下用户类型的所有角色
export const getSoftUserTypeRole = async (that, softId, refType, refId) => {
    const ret = await that.http(`/galaxyAuthority/softUsertypeRole/listAll/${softId}/${refType}/${refId}`);
    console.log("获取软件下用户类型的所有角色", ret);
    if (ret.success) {
        return ret.data
    } else {
        return false
    }
}
//新增软件下用户类型下的角色
export const postSoftUserTypeRole = async (that, form) => {
    const ret = await that.http(`/galaxyAuthority/softUsertypeRole/post`, form, "POST");
    console.log("新增软件下用户类型下的角色", ret);
    if (ret.success) {
        return true
    } else {
        return false
    }
}
//修改软件下用户类型下的角色
export const putSoftUserTypeRole = async (that, form) => {
    const ret = await that.http(`/galaxyAuthority/softUsertypeRole/put`, form, "PUT");
    console.log("修改软件下用户类型下的角色", ret);
    if (ret.success) {
        return true
    } else {
        return false
    }
}
//删除软件下用户类型下的角色
export const deleteSoftUserTypeRole = async (that, id) => {
    const ret = await that.http(`/galaxyAuthority/softUsertypeRole/${id}`, null, "DELETE");
    console.log("删除软件下用户类型下的角色", ret);
    if (ret.success) {
        return true
    } else {
        return false
    }
}
//获取软件下用户类型下角色的所有权限
export const getSoftUserTypeRoleAuthority = async (that, softId, usertypeId, softUsertypeRoleid) => {
    const ret = await that.http(`/galaxyAuthority/softUsertypeRoleAuthority/listAll/${softId}/${usertypeId}/${softUsertypeRoleid}`);
    console.log("获取软件下用户类型下角色的所有权限", ret);
    if (ret.success) {
        return ret.data
    } else {
        return []
    }
}
//修改软件下用户类型下角色的权限
export const putSoftUserTypeRoleAuthority = async (that, form) => {
    const ret = await that.http(`/galaxyAuthority/softUsertypeRoleAuthority/put`, form, "PUT");
    console.log("修改软件下用户类型下角色的权限", ret);
    if (ret.success) {
        return true
    } else {
        return false
    }
}
