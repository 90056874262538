<style lang="scss" scoped>
    .tree-expand {
        width: 100%;
        background-color: #fff;
    }

    .display-box {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .tree-btn {
            /*display: flex;*/
            width: 160px;
            text-align: center;
            justify-content: space-between;
            button {
                padding: 0
            }
        }
    }

    .edit-box {
        background-color: #fff;
        .head {
            display: flex;

            justify-content: space-between;
            /*background-color: #EFF7FF;*/
            button{
                font-size: 14px;
            }
        }
        .head-btns{
            width: 106.66px;
            text-align: center;
        }
        .edit-form {
            width: 60%;
        }

    }
</style>
<template>
    <div class="tree-expand">
        <div class="display-box" v-show="!DATA.isEdit">
            <div :class="[DATA.id > maxexpandId ? 'tree-new tree-label' : 'tree-label']">
                <span>{{DATA.refName}}</span>
            </div>
            <div class="tree-btn">
                <el-row>
                    <el-col :span="8">
                        <el-button type="text" @click.stop="nodeEdit(STORE,DATA,NODE)">编辑</el-button>
                    </el-col>
                    <el-col :span="8">
                        <el-button type="text" v-show="!DATA.hideAdd" @click.stop="nodeAdd(STORE,DATA,NODE)">添加</el-button>
                    </el-col>
                    <el-col :span="8">
                        <el-button type="text" style="color: #FF3B30" @click.stop="nodeDel(STORE,DATA,NODE)">删除</el-button>
                    </el-col>
                </el-row>



            </div>
        </div>
        <!--编辑状态-->
        <div class="edit-box" v-show="DATA.isEdit">
            <div class="head">
                <p>{{DATA.id ? '编辑项' : "新增项"}}：</p>
                <div class="btns head-btns">
                    <el-row>
                        <el-col :span="12">
                            <el-button type="text" @click.stop="nodeSave(STORE,DATA,NODE)">保存</el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-button type="text" @click.stop="nodeDel(STORE,DATA,NODE)">删除</el-button>
                        </el-col>
                    </el-row>


                </div>
            </div>
            <div class="edit-form">
                <el-form ref="formName" :model="DATA" label-width="80px">
                    <el-form-item label="类型">
                        <el-select v-model="DATA.refType" placeholder="请选择类型">
                            <el-option label="模块" value="module"></el-option>
                            <el-option label="页面" value="page"></el-option>
                            <el-option label="元素" value="element"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="ID">
                        <el-input v-model="DATA.refId" placeholder="输入ID" required></el-input>
                    </el-form-item>
                    <el-form-item label="名称">
                        <el-input v-model="DATA.refName" placeholder="输入名称" required></el-input>
                    </el-form-item>
                    <el-form-item label="内容">
                        <el-input v-model="DATA.refContent" placeholder="输入内容" required></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'treeExpand',
        props: ['NODE', 'DATA', 'STORE', 'maxexpandId'],
        methods: {
            nodeAdd(s, d, n) {//新增
                this.$emit('nodeAdd', s, d, n)
            },
            nodeEdit(s, d, n) {//编辑
                this.$emit('nodeEdit', s, d, n)
            },
            nodeSave(s, d, n) {
                d.isEdit = false;
                this.$emit('nodeSave', s, d, n)
            },
            nodeDel(s, d, n) {//删除
                this.$emit('nodeDel', s, d, n)
            }
        }
    }
</script>

