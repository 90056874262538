/**
* Created by hansxing on 2018/8/7.
*/
<style lang="scss" scoped>
    .user-type-per {
        /*padding: ;*/
        .type-per-query {
            background: #fff;
            padding: 20px;
            position: relative;
            ul {
                li {
                    float: left;
                    margin-right: 30px;
                    .el-select {
                        width: 185px;
                    }
                }
            }
            .per-query-btn {
                position: absolute;
                right: 20px;
                top: 20px;
                &.el-button {
                    padding: 12px 25px;
                }

            }

        }

        .type-per-tree {
            background: #fff;
            margin-top: 10px;
            /*margin-bottom: 80px;*/
            .el-tree {
                padding: 40px;
            }
        }

    }

    .eTrip-footer-cont {
        text-align: right;
        .el-button {
            width: 120px;
        }
        .el-button + .el-button {
            margin-left: 18px;
        }
    }
</style>
<style lang="scss">
    .type-per-tree{
        .el-tree-node__label{
            font-size: 14px;
        }

    }
    .user-type-per{
        .el-tree-node__content {
            line-height: 32px;
            height: 32px;
        }
    }

</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont user-type-per">
                <div style="background: #fff">
                    <div class="type-per-query">

                        <ul class="clear">
                            <li>
                                <span>软件：</span>
                                <el-select v-model="choiceSoftId" placeholder="请选择软件">
                                    <el-option
                                            v-for="(item,idx) in softList"
                                            :key="idx"
                                            :label="item.softName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </li>
                            <li>
                                <span>用户类型：</span>
                                <el-select v-model="choiceUserType" @change="getListData" placeholder="请选择用户类型">
                                    <el-option
                                            v-for="(item,idx) in userTypeList"
                                            :key="idx"
                                            :label="item.usertypeName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </li>
                        </ul>
                        <el-button type="primary" class="per-query-btn" @click="getListData">搜索</el-button>

                    </div>
                </div>
                <div class="type-per-tree" v-loading="loading">
                    <el-tree
                            :default-expand-all="true"
                            :check-strictly="checkStrictly"
                            v-if="!loading"
                            ref="dataTree"
                            :data="dataList"
                            show-checkbox
                            node-key="id"
                            :default-checked-keys="defaultCheckedKeys"
                            :props="defaultProps">
                    </el-tree>
<!--                    @check-change="handleCheckChange"-->
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <!--<el-button @click="handleCancel">取消</el-button>-->
                <el-button type="primary" @click="handleSave()">保存设置</el-button>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
    import {
        getSoftList,
        getUserTypeList,
        getSoftUserTypeAuthority,
        putSoftUserTypeAuthority,
        getSoftAuthority
    } from "../service"

    export default {
        name: "userTypePer",
        //定义模版数据
        data() {
            return {
                checkStrictly: true,
                softList: null,
                choiceSoftId: null,
                userTypeList: null,
                choiceUserType: null,
                checkedDataList: [],
                dataList: [],
                defaultCheckedKeys: [],
                loading: true,
                defaultProps: {
                    children: 'nodeList',
                    label: 'refName'
                }
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.init();
        },
        //定义事件方法
        methods: {
            async init() {
                //初始化软件类型
                this.softList = await getSoftList(this) || [];
                if (this.softList.length > 0)
                    this.choiceSoftId = this.softList[0].id;
                //初始化用户类型
                this.userTypeList = await getUserTypeList(this) || [];
                if (this.userTypeList.length > 0)
                    this.choiceUserType = this.userTypeList[0].id;
                this.getListData();
                this.loading = false;

            },
            async getListData() {
                this.checkStrictly = true;
                this.loading = true;
                this.dataList = await getSoftAuthority(this, this.choiceSoftId);
                this.checkedDataList = await getSoftUserTypeAuthority(this, this.choiceSoftId, this.choiceUserType);
                let defaultCheckedKeys = [];
                this.checkedDataList.forEach((data) => {
                    defaultCheckedKeys.push(data.refNid)
                })
                this.defaultCheckedKeys = defaultCheckedKeys;
                this.loading = false;

                setTimeout(()=>{
                    this.checkStrictly = false;
                },200)


            },
            async handleSave() {
                let list = this.$refs.dataTree.getCheckedNodes();
                let halfList = this.$refs.dataTree.getHalfCheckedNodes();
                list = list.concat(halfList);
                if (list.length === 0) {
                    this.$message.error("未选择任何项")
                    return
                }
                this.loading = true;

                list.forEach((data) => {
                    delete data.nodeList
                    data.refPid = data.pid
                    data.refNid = data.id
                    data.usertypeId = this.choiceUserType
                    delete data.pid
                    delete data.id
                })
                let ret = await putSoftUserTypeAuthority(this, list)
                if (ret) {
                    this.$message.success("保存设置成功！")
                    this.getListData();
                }
                else {
                    this.loading = false;
                    this.$message.error("保存设置失败！")
                }


            }

        },
        //监听模版变量
        watch: {}

    }
</script>

