/**
* Created by hansxing on 2018/8/7.
*/
<style lang="scss" scoped>
    .user-type-per {
        .type-per-query {
            background: #fff;
            padding: 20px;
            position: relative;
            ul {
                width: 867px;
                li {
                    float: left;
                    margin-right: 30px;
                    .el-select {
                        width: 185px;
                    }
                }
            }
            .per-query-btn {
                position: absolute;
                right: 20px;
                top: 20px;
                &.el-button {
                    padding: 12px 25px;
                }

            }

        }

        .type-per-tree {
            background: #fff;
            margin-top: 10px;
            /*margin-bottom: 80px;*/
        }

    }

    .eTrip-footer-cont {
        text-align: right;
        .el-button {
            width: 120px;
        }
        .el-button + .el-button {
            margin-left: 18px;
        }
    }

    .add-button {
        margin-top: 20px;
    }
</style>
<style lang="scss">
    .type-per-tree {
        .table-info-operation{
            .el-button{
                padding: 0;
            }
        }
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="user-type-per eTrip-section-cont">
                <div style="background: #fff">
                    <div class="type-per-query">
                        <ul class="clear">
                            <li>
                                <span>软件：</span>
                                <el-select v-model="choiceSoftId"
                                           placeholder="请选择软件">
                                    <el-option v-for="(item,idx) in softList"
                                               :key="idx"
                                               :label="item.softName"
                                               :value="item.id">
                                    </el-option>
                                </el-select>
                            </li>
                            <li>
                                <span>用户类型：</span>
                                <el-select v-model="choiceUserType"
                                           placeholder="请选择用户类型">
                                    <el-option v-for="(item,idx) in userTypeList"
                                               :key="idx"
                                               :label="item.usertypeName"
                                               :value="idx">
                                    </el-option>
                                </el-select>
                            </li>
                        </ul>
                        <el-button type="primary" class="per-query-btn" @click="getUserRole">搜索</el-button>

                    </div>
                </div>
                <div class="add-button">
                    <el-button type="primary" class="per-query-btn" @click="dialogTableVisible=true">添加角色</el-button>
                </div>
                <div class="type-per-tree" v-loading="loading">
                    <el-table
                            :data="userRole"
                            style="width: 100%"
                            max-height="250">
                        <el-table-column
                                fixed
                                prop="roleName"
                                label="角色名称"
                                align="center"
                                width="110">
                        </el-table-column>
                        <el-table-column
                                prop="roleDesc"
                                label="角色内容"
                                align="center"
                                min-width="240">
                        </el-table-column>
                        <el-table-column :class-name="('table-info-operation')"
                                fixed="right"
                                label="操作"
                                align="center"
                                width="100">
                            <template slot-scope="scope">
                                <el-button
                                        @click.native.prevent="handleEdit(scope.$index,scope.row)"
                                        type="text">
                                    编辑
                                </el-button>
                                <el-button
                                        @click.native.prevent="handleDelete(scope.$index,scope.row)"
                                        type="text">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <el-dialog title="添加角色" :visible.sync="dialogTableVisible">
                <el-form ref="form" :model="form" label-width="80px" :rules="rules">
                    <el-form-item label="角色名称" required prop="roleName">
                        <el-input v-model="form.roleName"></el-input>
                    </el-form-item>
                    <el-form-item label="角色内容">
                        <el-input v-model="form.roleDesc"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogTableVisible = false">取 消</el-button>
                    <el-button type="primary" @click="handleSave()">保存</el-button>
                </span>
            </el-dialog>
        </el-main>
    </div>
</template>

<script type="text/ecmascript-6">
    import {
        getSoftList,
        getUserTypeList,
        getSoftUserTypeRole,
        postSoftUserTypeRole,
        deleteSoftUserTypeRole,
        putSoftUserTypeRole
    } from "../service"

    export default {
        name: "userTypePer",
        //定义模版数据
        data() {
            return {
                softList: null,
                choiceSoftId: null,
                userTypeList: null,
                choiceUserType: 0,
                checkedDataList: [],
                userRole: [],
                choiceUserRole: null,
                loading: true,
                dialogTableVisible: false,
                form: {
                    roleDesc: null,
                    roleName: null
                },
                rules: {
                    roleName: {required: true, message: '请输入角色名称', trigger: 'blur'}
                }
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.init();
        },
        //定义事件方法
        methods: {
            async init() {
                //初始化软件类型
                this.softList = await getSoftList(this) || [];
                if (this.softList.length > 0)
                    this.choiceSoftId = this.softList[0].id;
                //初始化用户类型
                this.userTypeList = await getUserTypeList(this) || [];
                if (this.userTypeList.length > 0)
                    this.choiceUserType = 0;
                this.getUserRole();
                this.loading = false;
            },
            async getUserRole() {
                this.loading = true
                //初始化用户类型
                const userType = this.userTypeList[this.choiceUserType] || {}
                this.userRole = await getSoftUserTypeRole(this, this.choiceSoftId, userType.id, userType.usertypeName) || [];
                if (this.userRole.length > 0)
                    this.choiceUserRole = this.userRole[0].id;
                this.loading = false

            },
            handleSave() {
                this.$refs["form"].validate(async (valid) => {
                    if (valid) {
                        this.dialogTableVisible = false;

                        if (this.form.id) {
                            //编辑
                            const ret = await putSoftUserTypeRole(this, this.form);
                            this.form = {
                                roleDesc: null,
                                roleName: null
                            }
                            if (ret) {
                                this.$message.success("成功修改角色")
                                this.getUserRole();
                            }
                            else
                                this.$message.success("修改角色失败")
                        } else {
                            const form = {
                                refId: this.userTypeList[this.choiceUserType].usertypeName,//关联id:供应商id或运营商id ,
                                refType: this.userTypeList[this.choiceUserType].id,//关联类型:1供应商，2运营商 ,
                                roleDesc: this.form.roleDesc,//角色描述 ,
                                roleName: this.form.roleName,//角色名称 ,
                                softId: this.choiceSoftId// 软件id
                            }

                            //新增
                            const ret = await postSoftUserTypeRole(this, form);
                            if (ret) {
                                this.$message.success("成功添加角色")
                                this.getUserRole();
                            }
                            else
                                this.$message.success("添加角色失败")
                        }


                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            handleCancel() {

            },
            handleEdit(idx, data) {
                console.log(data);
                this.form = data;
                this.dialogTableVisible = true;
            },
            handleDelete(idx, data) {
                this.$confirm('确定删除该权限吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const ret = await deleteSoftUserTypeRole(this, data.id);
                    if (ret) {
                        this.$message.success("删除权限成功")
                        this.getUserRole()

                    } else {
//                        this.$message.error(ret.message||"权限删除失败")
                    }
                }).catch(() => {
                });

            }

        },
        //监听模版变量
        watch: {}

    }
</script>

