/**
* Created by hansxing on 2018/8/7.
*/
<style lang="scss" scoped>
    .user-type-per {
        .type-per-query {
            background: #fff;
            padding: 20px;
            position: relative;
            ul {
                width: 867px;
                li {
                    float: left;
                    margin-right: 30px;
                    .el-select {
                        width: 185px;
                    }
                }
            }
            .per-query-btn {
                position: absolute;
                right: 20px;
                top: 20px;
                &.el-button {
                    padding: 12px 25px;
                }

            }

        }

        .type-per-tree {
            background: #fff;
            margin-top: 10px;
            /*margin-bottom: 80px;*/
        }

    }

    .eTrip-footer-cont {
        text-align: right;
        .el-button {
            width: 120px;
        }
        .el-button + .el-button {
            margin-left: 18px;
        }
    }
</style>
<style lang="scss">
    .type-per-tree{
        .el-tree{
            padding: 40px;
        }
        .el-tree-node__label{
            font-size: 14px;
        }
        .el-tree-node__content {
            line-height: 32px;
            height: 32px;
        }
    }


</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="user-type-per eTrip-section-cont">
                <div style="background: #fff">
                    <div class="type-per-query">
                        <ul class="clear">
                            <li>
                                <span>软件：</span>
                                <el-select v-model="choiceSoftId"
                                           @change="getUserRole"
                                           placeholder="请选择软件">
                                    <el-option v-for="(item,idx) in softList"
                                               :key="idx"
                                               :label="item.softName"
                                               :value="item.id">
                                    </el-option>
                                </el-select>
                            </li>
                            <li>
                                <span>用户类型：</span>
                                <el-select v-model="choiceUserType"
                                           @change="getUserRole"
                                           placeholder="请选择用户类型">
                                    <el-option v-for="(item,idx) in userTypeList"
                                               :key="idx"
                                               :label="item.usertypeName"
                                               :value="idx">
                                    </el-option>
                                </el-select>
                            </li>
                            <li>
                                <span>用户角色：</span>
                                <el-select v-model="choiceUserRole"
                                           @change="getListData"
                                           placeholder="请选择用户类型">
                                    <el-option
                                            v-for="(item,idx) in userRole"
                                            :key="idx"
                                            :label="item.roleName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </li>
                        </ul>
                        <el-button type="primary" class="per-query-btn" @click="getListData">搜索</el-button>

                    </div>
                </div>
                <div class="type-per-tree" v-loading="loading">
                    <el-tree
                            :default-expand-all="true"
                            :check-strictly="checkStrictly"
                            v-if="!loading"
                            ref="dataTree"
                            :data="dataList"
                            show-checkbox
                            node-key="refNid"
                            :default-checked-keys="defaultCheckedKeys"
                            :props="defaultProps">
                    </el-tree>
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <!--<el-button @click="handleCancel">取消</el-button>-->
                <el-button type="primary" @click="handleSave()">保存设置</el-button>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
    import {
        getSoftList,
        getUserTypeList,
        getSoftUserTypeAuthority,
        getSoftUserTypeRole,
        getSoftUserTypeRoleAuthority,
        putSoftUserTypeRoleAuthority
    } from "../service"

    export default {
        name: "userTypePer",
        //定义模版数据
        data() {
            return {
                checkStrictly: true,
                softList: null,
                choiceSoftId: null,
                userTypeList: null,
                choiceUserType: 0,
                checkedDataList: [],
                userRole: [],
                dataList: [],
                defaultCheckedKeys: [],
                choiceUserRole: null,
                loading: true,
                defaultProps: {
                    children: 'nodeList',
                    label: 'refName'
                }
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.init();
        },
        //定义事件方法
        methods: {
            async init() {
                //初始化软件类型
                this.softList = await getSoftList(this) || [];
                if (this.softList.length > 0)
                    this.choiceSoftId = this.softList[0].id;
                //初始化用户类型
                this.userTypeList = await getUserTypeList(this) || [];
                if (this.userTypeList.length > 0)
                    this.choiceUserType = 0;

                await this.getUserRole();

                this.loading = false;
            },
            async getUserRole() {
                //初始化用户类型
                const userType = this.userTypeList[this.choiceUserType] || {}
                this.userRole = await getSoftUserTypeRole(this, this.choiceSoftId, userType.id, userType.usertypeName) || [];
                this.choiceUserRole = null;
                this.dataList = [];
                this.defaultCheckedKeys = [];
                if (this.userRole.length > 0)
                    this.choiceUserRole = this.userRole[0].id;

                if (this.choiceUserRole)
                    await this.getListData();
                else
                    this.$message.warning("暂无角色数据")

            },
            async getListData() {
                if (!this.choiceUserRole)
                    return
                this.checkStrictly = true;
                this.loading = true;
                const userTypeId = this.userTypeList[this.choiceUserType].id;
                let defaultCheckedKeys = [];
                this.dataList = await getSoftUserTypeAuthority(this, this.choiceSoftId, userTypeId, 1);
                this.checkedDataList = await getSoftUserTypeRoleAuthority(this, this.choiceSoftId, userTypeId, this.choiceUserRole);
                this.checkedDataList.forEach((data) => {
                    defaultCheckedKeys.push(data.nid)
                })
                console.log("33333", defaultCheckedKeys);
                this.defaultCheckedKeys = defaultCheckedKeys;
                this.loading = false;
                setTimeout(() => {
                    this.checkStrictly = false;
                }, 200)

            },
            async handleSave() {
                let list = this.$refs.dataTree.getCheckedNodes();
                let halfList = this.$refs.dataTree.getHalfCheckedNodes();
                list = JSON.parse(JSON.stringify(list.concat(halfList)));
                if (list.length === 0) {
                    this.$message.error("未选择任何项")
                    return
                }
                this.loading = true;

                list.forEach((data) => {
                    data.name = data.refName
                    data.content = data.refContent
                    data.softUsertypeRoleid = this.choiceUserRole
                    data.usertypeId = this.userTypeList[this.choiceUserType].id
                    data.pid = data.refPid
                    data.nid = data.refNid
                    delete data.id
                    delete data.refId
                    delete data.nodeList
                    delete data.refName
                    delete data.refContent
                    delete data.refType
                    delete data.refPid
                    delete data.refNid

                })

                let ret = await putSoftUserTypeRoleAuthority(this, list)

                if (ret) {
                    this.$message.success("保存设置成功！")
                    this.getListData();
                }
                else {
                    this.$message.error("保存设置失败！")

                    this.loading = false;

                }


            },
            handleCancel() {

            }

        },
        //监听模版变量
        watch: {}

    }
</script>

