/**
* Created by hansxing on 2018/8/7.
*/
<style lang="scss" scoped>
.per-setting {
    .setting-query {
        background: #fff;
        padding: 20px;
        position: relative;

        ul {
            li {
                float: left;
                margin-right: 30px;

                .el-select {
                    width: 185px;
                }
            }
        }

        .query-btn {
            position: absolute;
            right: 20px;
            top: 20px;

            &.el-button {
                padding: 12px 25px;
            }

        }

    }

    .setting-cont {
        background: #fff;
        margin-top: 10px;

        .expand {
            width: 100%;
            height: 80%;
            overflow: hidden;
        }

        .expand > div {
            overflow-y: auto;
            padding: 40px;
        }

        .expand > div::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            border-radius: 5px;
        }

        .expand > div::-webkit-scrollbar-thumb {
            background-color: rgba(50, 65, 87, 0.5);
            outline: 1px solid slategrey;
            border-radius: 5px;
        }

        .expand > div::-webkit-scrollbar {
            width: 10px;
        }

        .expand-tree {
            border: none;
            margin-top: 10px;
        }

        .expand-tree .el-tree-node.is-current,
        .expand-tree .el-tree-node:hover {
            overflow: hidden;
        }

        .expand-tree .is-current > .el-tree-node__content .tree-btn,
        .expand-tree .el-tree-node__content:hover .tree-btn {
            display: inline-block;
        }

        .expand-tree .is-current > .el-tree-node__content .tree-label {
            font-weight: 600;
            white-space: normal;
        }
    }
}

</style>
<style lang="scss">
.per-setting {
    .el-tree-node__content {
        display: flex;
        align-items: flex-start;
        line-height: 32px;
        height: 32px;
    }

    .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content,
    .el-tree--highlight-current .el-tree-node__content:hover {
        background-color: #fff !important;
    }

    .el-tree-node__content > .el-tree-node__expand-icon {
        padding: 10px 6px;
    }

    .display-box {
        .tree-label {
            font-size: 14px;
        }

        &:hover {
            background: #f5f7fa;

            .tree-btn {
                display: block;
            }
        }

        .tree-btn {
            display: none;

            button {
                font-size: 14px;
            }

        }
    }

    .el-tree-node__content {
        height: auto;
    }
}

</style>

<template>
    <div>

        <el-main class="com-eTrip-section">

            <div class="eTrip-section-cont per-setting">
                <div class="setting-query">
                    <ul class="clear">
                        <li>
                            <span>软件：</span>
                            <el-select v-model="choiceSoftId"
                                       placeholder="请选择软件"
                                       @change="handleChange"
                            >
                                <el-option
                                    v-for="(item,idx) in softList"
                                    :key="idx"
                                    :label="item.softName"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </li>
                    </ul>
                    <!--<el-button type="primary" class="query-btn">搜索</el-button>-->

                </div>

                <div class="setting-cont">
                    <div class="expand">
                        <div>
                            <el-button type="primary" @click="handleAddTop">添加顶级权限</el-button>
                            <el-tree ref="expandMenuList" class="expand-tree"
                                     :default-expand-all="true"
                                     v-if="isLoadingTree"
                                     :data="softTree"
                                     node-key="id"
                                     highlight-current
                                     :props="defaultProps"
                                     :expand-on-click-node="false"
                                     :render-content="renderContent"
                                     :default-expanded-keys="defaultExpandKeys"
                                     @node-click="handleNodeClick">
                            </el-tree>
                        </div>
                    </div>
                </div>

            </div>
        </el-main>
    </div>
</template>

<script type="text/ecmascript-6">
import TreeRender from './tree_render'
import {getSoftList, getSoftAuthority, postSoftAuthority, putSoftAuthority, deleteSoftAuthority} from '../service'

export default {
    name: 'perSetting',
    //定义模版数据
    data() {
        return {
            maxexpandId: 95,//新增节点开始id
            non_maxexpandId: 95,//新增节点开始id(不更改)
            isLoadingTree: false,//是否加载节点树
            softTree: [{
                id: 2,
                refId: null,
                refName: "天津市",
                usertypeId: null,
                softId: null,
                isEdit: false,
                nodeList: [{
                    id: 21,
                    refId: 2,
                    refName: "东丽区",
                    usertypeId: null,
                    softId: null,
                    isEdit: false,
                    nodeList: []
                }]
            }],
            defaultProps: {
                children: 'nodeList',
                label: 'refName'
            },
            defaultExpandKeys: [],//默认展开节点列表
            softList: null,
            choiceSoftId: null
        }
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: async function () {
        this.softList = await getSoftList(this) || [];
        if (this.softList.length > 0)
            this.choiceSoftId = this.softList[0].id;
        this.softTree = await getSoftAuthority(this, this.choiceSoftId);
        this.initExpand()
    },
    //定义事件方法
    methods: {
        initExpand() {
            this.softTree.map((a) => {
                this.defaultExpandKeys.push(a.id)
            });
            this.isLoadingTree = true;
        },
        handleNodeClick() {//点击节点
        },
        renderContent(h, {node, data, store}) {//加载节点
            let that = this;
            return h(TreeRender, {
                props: {
                    DATA: data,
                    NODE: node,
                    STORE: store,
                    maxexpandId: that.non_maxexpandId
                },
                on: {
                    nodeAdd: ((s, d, n) => that.handleAdd(s, d, n)),
                    nodeEdit: ((s, d, n) => that.handleEdit(s, d, n)),
                    nodeDel: ((s, d, n) => that.handleDelete(s, d, n)),
                    nodeSave: ((s, d, n, form) => that.handleSave(s, d, n, form))
                }
            });
        },
        handleAddTop() {
            this.softTree.push({
                id: "",
                nodeList: [],
                refContent: "",
                refId: "",
                refName: "",
                refType: "module",
                isEdit: true,
                softId: this.choiceSoftId
            })
        },
        handleAdd(s, d, n) {//增加节点
            console.log(s);
            if (n.level >= 6) {
                this.$message.error("最多只支持五级！")
                return false;
            }
            //添加数据
            d.nodeList.push({
                id: "",
                nodeList: [],
                pid: d.id,
                refContent: "",
                refId: "",
                refName: "",
                refType: "module",
                isEdit: true,
                softId: d.softId
            });
            //展开节点
            if (!n.expanded) {
                n.expanded = true;
            }
        },
        handleEdit(s, d) {//编辑节点
            console.log(s);
            let arr = JSON.parse(JSON.stringify(this.softTree))
            this.handleObject(arr, d.id, {isEdit: true});
            this.softTree = arr;
        },
        handleDelete(s, d, n) {//删除节点
            console.log(s);
            let that = this;
            //有子级不删除
            if (d.nodeList && d.nodeList.length !== 0) {
                this.$message.error("此节点有子级，不可删除！")
                return false;
            } else {
                let delNode = () => {
                    let list = n.parent.data.nodeList || n.parent.data,//节点同级数据
                        _index = 99999;//要删除的index
                    list.map((c, i) => {
                        if (d.id == c.id) {
                            _index = i;
                        }
                    })
                    console.log(_index);
                    this.$message.success("删除成功！")
                }
                let isDel = () => {
                    that.$confirm("是否删除此节点？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(async () => {
                        if (d.id) {
                            let ret = deleteSoftAuthority(this, d.id);
                            if (ret)
                                delNode()
                            else
                                this.$message.error("删除权限失败！")
                        } else
                            delNode()


                    }).catch(() => {
                        return false;
                    })
                }
                //判断是否新增
                d.id > this.non_maxexpandId ? delNode() : isDel()

            }
        },
        async handleSave(s, d) {
            console.log(s);
            if (!d.id) {
                let ret = await postSoftAuthority(this, d);
                if (ret) {
                    d.id = ret
                    this.$message.success("权限添加成功！")
                    delete d.isEdit;
                } else
                    this.$message.error("权限添加失败！")
            } else {
                let ret = putSoftAuthority(this, d);
                if (ret) {
                    this.$message.success("权限修改成功！")
                    delete d.isEdit;
                } else
                    this.$message.error("权限修改失败！")
            }


        },
        handleObject(arr, id, obj) {
            arr.map((data) => {
                if (data.id === id)
                    data = Object.assign(data, obj);
                if (data.nodeList.length > 0)
                    this.handleObject(data.nodeList, id, obj);
            })
        },
        async handleChange() {
            this.softTree = await getSoftAuthority(this, this.choiceSoftId);
            this.initExpand()
        }
    },
    //监听模版变量
    watch: {}

}
</script>

